import customerService from '@/services/customer';

export default {
  name: 'CustomerProfileAttributes',
  data() {
    return {
      selectedTab: 'custom',
      totalSystemAttributes: 0,
      totalCustomAttributes: 0,

      fetchingData: true,
      allAttributes: [],
      currentTabAttributes: [],

      // Create attribute members
      showCreateAttributeModal: false,
      createAttributeForm: {
        name: '',
        type: 'string'
      },
      dataTypeList: [
        {
          name: 'Text',
          value: 'string'
        },
        {
          name: 'Number',
          value: 'double precision'
        },
        {
          name: 'Date (Format: YYYY-MM-DD HH:MM:SS)',
          value: 'date'
        },
        {
          name: 'True/False',
          value: 'boolean'
        }
      ],
      createAttributeFormRules: {
        name: [
          {
            required: true,
            message: 'Please enter attribute name',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: 'Length should be 3 to 50 characters',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    onTabChange(attributeType) {
      this.selectedTab = attributeType;

      this.currentTabAttributes = [];
      for (let i = 0; i < this.allAttributes.length; i++) {
        if (this.selectedTab === 'custom') {
          if (this.allAttributes[i].isCustom) {
            this.currentTabAttributes.push(this.allAttributes[i]);
          }
        } else if (this.selectedTab === 'system') {
          if (!this.allAttributes[i].isCustom) {
            this.currentTabAttributes.push(this.allAttributes[i]);
          }
        }
      }
    },

    async fetchList() {
      try {
        this.fetchingData = true;

        this.allAttributes = [];

        let response = await customerService.fetchUserPropertyList(null);

        this.allAttributes = response.data.data;

        this.totalSystemAttributes = 0;
        this.totalCustomAttributes = 0;
        for (let i = 0; i < this.allAttributes.length; i++) {
          if (this.allAttributes[i].isCustom) {
            this.totalCustomAttributes++;
          } else {
            this.totalSystemAttributes++;
          }
        }

        this.onTabChange('custom');

        this.fetchingData = false;
      } catch (e) {
        console.log('Okay, failure here..', e);
      }
    },

    // #region ------------------------ Start: Create attribute ------------------------

    async validateCreateAttributeForm() {
      return new Promise((resolve) => {
        this.$refs.createAttributeForm.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },

    async onCreateAttribute() {
      try {
        let isValid = await this.validateCreateAttributeForm();
        console.log('Validation Result: ' + isValid);

        if (isValid) {
          let response = await customerService.createCustomerAttribute(this.createAttributeForm);
          if (response.data.success) {
            this.successToast('Attribute created successfully.');
            this.showCreateAttributeModal = false;
            this.fetchList();
          } else {
            this.errorToast(response.data.message);
          }
        }
      } catch (err) {
        this.errorToast('Failed to create attribute. Please contact support.');
      }
    }

    // #endregion --------------------- End: Create attribute ---------------------
  },

  mounted() {
    this.fetchList();
  }
};
