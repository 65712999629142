<template>
  <div class="customerProfileAttributesPage">
    <!-- Header -->
    <div class="flex">
      <div class="flex-1">
        <div class="text-2xl font-semibold text-gray-900">Customer Attributes</div>
        <p class="mt-1 text-sm text-gray-700">List of all customer attributes being tracked with Growlytics.</p>
      </div>
      <div>
        <el-button type="primary" @click="showCreateAttributeModal = true">Add Custom Attribute</el-button>
      </div>
    </div>

    <!-- List -->
    <div class="bg-white border rounded-xl">
      <div class="tabContainer border-b">
        <nav class="flex" aria-label="Tabs">
          <div v-for="(attributeType, index) in ['system', 'custom']" :key="index" class="tabItem border-transparent text-gray-700 hover:text-primary-700 text-center py-2.5 px-5 font-semibold" :class="{ selectedTab: selectedTab == attributeType }" @click="onTabChange(attributeType)">
            <div class="mb-1 flex align-center justify-center">
              <div class="text-sm leading-4" style="margin-right: 6px">{{ toPascalCase(attributeType) }}</div>
              <span class="countTag bg-gray-700 text-white w-auto px-2 rounded-full">
                {{ attributeType == 'system' ? totalSystemAttributes : totalCustomAttributes }}
              </span>
            </div>
          </div>
        </nav>
      </div>

      <el-row class="py-2.5 px-3 border-b text-sm bg-primary-50 font-bold">
        <el-col :span="8"> Attribute Name </el-col>
        <el-col :span="12"> Attribute Data Type </el-col>
      </el-row>

      <!-- List -->
      <el-row class="py-2.5 px-3 border-b text-sm text-gray-800" v-for="(attribute, index) in currentTabAttributes" :key="index">
        <el-col :span="8">
          {{ attribute.name }}
        </el-col>
        <el-col :span="12">
          {{ attribute.typeDisplayText ? attribute.typeDisplayText.toUpperCase() : attribute.typeDisplayText }}
        </el-col>
        <!-- <el-col :span="4"> Settings </el-col> -->
      </el-row>
    </div>

    <!-- Create Dialog -->
    <el-dialog custom-class="createCustomerAttributeDailog" title="Create Custom Attribute" :visible.sync="showCreateAttributeModal" width="500px">
      <el-form :model="createAttributeForm" label-position="top" :rules="createAttributeFormRules" ref="createAttributeForm" label-width="120px" direction="vertical">
        <el-form-item label="" prop="name">
          <div class="leading-6 text-xs text-gray-500">Attribute Name</div>
          <el-input v-model="createAttributeForm.name" placeholder="Enter attribute name"></el-input>
        </el-form-item>
        <el-form-item label="" prop="type">
          <div class="leading-6 text-xs text-gray-500">Attribute Data Type</div>
          <el-select v-model="createAttributeForm.type" placeholder="Select attribute data type">
            <el-option v-for="(dataType, index) in dataTypeList" :key="index" :label="dataType.name" :value="dataType.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="onCreateAttribute">Create Attribute</el-button>
        <el-button type="danger" size="small" plain @click="showCreateAttributeModal = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" src="./customerProfileAttributes.scss"></style>

<script>
import customerProfileAttributesComponent from './customerProfileAttributesComponent';
export default customerProfileAttributesComponent;
</script>
